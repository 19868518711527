import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { createStructuredSelector } from 'reselect';
import prop from 'lodash/fp/prop';
import { flow } from 'lodash-es';
import useSimpleForm from 'hooks/useSimpleForm';
import ToastContext from 'contexts/Toast';
import { useMutateMerchant } from './useMerchant';

const GOOGLE_MERCHANT_ID_PATTERN = /^\d{9,15}$/;

const useRules = () => {
  return useMemo(() => {
    return {
      googleMerchantId: {
        required: {
          value: true,
          message: {
            key: 'required',
            options: {
              ns: 'fieldValidation',
            },
          },
        },
        pattern: {
          value: GOOGLE_MERCHANT_ID_PATTERN,
          message: {
            key: 'pattern',
            options: {
              ns: 'fieldValidation',
            },
          },
        },
      },
      preorderPeriod: {
        required: {
          value: true,
          message: {
            key: 'required',
            options: {
              ns: 'fieldValidation',
            },
          },
        },
        pattern: {
          value: /^\d+$/,
          message: {
            key: 'Please enter numbers only',
            options: {
              ns: 'main',
            },
          },
        },
        min: {
          value: 30,
          message: {
            key: 'min',
            options: {
              ns: 'fieldValidation',
              value: 30,
            },
          },
        },
        max: {
          value: 364,
          message: {
            key: 'max',
            options: {
              ns: 'fieldValidation',
              value: 364,
            },
          },
        },
      },
      contentApiToken: {
        required: {
          value: true,
          message: {
            key: 'required',
            options: {
              ns: 'fieldValidation',
            },
          },
        },
      },
    };
  }, []);
};

const merchantSettingSelector = createStructuredSelector({
  googleMerchantId: prop('googleMerchantId'),
  preorderPeriod: flow(prop('preorderPeriod'), Number),
  contentApiToken: prop('contentApiToken'),
});

const useMerchantForm = ({ defaultValues }) => {
  const { t } = useTranslation('main');
  const { createUIToast } = useContext(ToastContext);
  const [hasApiError, SetHasApiError] = useState(false);
  const onSuccess = useCallback(() => {
    createUIToast({
      id: nanoid(),
      type: 'success',
      titleWithParams: {
        key: 'main:Merchant has been saved successfully',
      },
    });
  }, [createUIToast]);
  const onError = useCallback(
    (error) => {
      if (error.response?.data?.code === 'E1001') {
        SetHasApiError(true);
        createUIToast({
          id: nanoid(),
          type: 'alert',
          titleWithParams: {
            key: 'main:Failed to save merchant',
          },
        });
      }
    },
    [SetHasApiError, createUIToast],
  );
  const { mutate, isLoading: submitting } = useMutateMerchant({
    onSuccess,
    onError,
  });
  const patchMerchantSetting = useCallback(
    (merchant) => {
      SetHasApiError(false);
      const merchantSetting = merchantSettingSelector(merchant);
      mutate(merchantSetting);
    },
    [mutate],
  );
  const rules = useRules();
  const [values, meta] = useSimpleForm({
    defaultValues,
    rules,
    onSubmit: patchMerchantSetting,
  });

  const renderGoogleMerchantIdMessages = useMemo(
    () => [
      () => {
        const message = meta.errors.googleMerchantId;
        return t(message.key, {
          ...message.options,
          fieldName: t('Google Merchant ID'),
        });
      },
    ],
    [meta.errors.googleMerchantId, t],
  );

  const renderPreorderPeriodMessages = useMemo(
    () => [
      () => {
        const message = meta.errors.preorderPeriod;
        return t(message.key, {
          ...message.options,
          fieldName: t('Preorder Period'),
        });
      },
    ],
    [meta.errors.preorderPeriod, t],
  );

  const formMeta = useMemo(
    () => ({
      ...meta,
      hasApiError: hasApiError,
    }),
    [meta, hasApiError],
  );

  return [
    values,
    formMeta,
    {
      submitting,
      renderMessages: {
        googleMerchantId: renderGoogleMerchantIdMessages,
        preorderPeriod: renderPreorderPeriodMessages,
      },
    },
  ];
};

export default useMerchantForm;
