import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ImageUploadZone, Icons } from '@shopline/dashboard-ui';
import { Trans, useTranslation } from 'react-i18next';
import Divider from 'components/Divider';
import { Text } from 'components';
import Spacer from 'components/Spacer';
import usePermissions from '../../hooks/usePermissions';
import { Field, FormFooter, Input } from './components';
import { useQueryMerchant } from './useMerchant';
import useMerchantForm from './useMerchantForm';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { env } from 'utils/env';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-merchant-form`;

const StyledImageUploadZone = styled(ImageUploadZone)`
  > div {
    cursor: pointer;
    padding: 16px;
  }

  svg {
    fill: #596480;
  }
`;

const SelectedFileBox = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d3dde6;
  border-radius: 6px;
  padding: 7px 15px;

  > div.fileName {
    &.hoverable:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    display: flex;
    justify-content: left;
    align-items: center;

    > svg {
      padding-right: 8px;
    }
  }

  > div.trashCan {
    display: flex;
    cursor: pointer;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
  }
`;

// @return object { name: string, content: string }
const processToken = (file) => {
  return new Promise((resolve) => {
    const loader = new FileReader();
    loader.onload = (loadEvent) => {
      const target = loadEvent.target || {};
      if (target.readyState !== 2) {
        resolve(null);
        return;
      }
      if (target.error) {
        console.error(target.error);
        resolve(null);
        return;
      }

      resolve({ name: file.name, content: target.result });
    };

    if (!file) {
      resolve(null);
      return;
    }
    loader.readAsText(file);
  });
};

const downloadText = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const MerchantForm = () => {
  const { t, i18n } = useTranslation(['main', 'common']);
  const canEdit = usePermissions('merchant', 'edit');
  const { data: merchant } = useQueryMerchant();
  const [
    { googleMerchantId, preorderPeriod, contentApiToken },
    { errors, handlers, handleSubmit, hasErrors, hasApiError, touched },
    { renderMessages, submitting },
  ] = useMerchantForm({
    defaultValues: merchant,
  });
  const renderPreorderPeriodPreText = useCallback(() => t('UNIT.days'), [t]);
  const FAQHref = `https://developers.google.com/shopping-content/guides/how-tos/service-accounts?hl=${i18n.language}`;
  const adminEmail = env('REACT_APP_GMC_ADMIN_EMAIL');
  const hasSelectedToken = contentApiToken?.content;
  const hasIntegrated =
    typeof merchant.googleMerchantId === 'string' &&
    merchant.googleMerchantId.length > 0 &&
    merchant.googleMerchantId === googleMerchantId;
  const initShowFileUpload = !hasIntegrated && !hasSelectedToken;
  const [showFileUpload, setShowFileUpload] = useState(initShowFileUpload);
  const removeToken = useCallback(() => {
    handlers.contentApiToken.onChange(null);
    handlers.contentApiToken.onBlur({ target: { value: null } });
    setShowFileUpload(true);
  }, [handlers, setShowFileUpload]);
  const setToken = useCallback(
    (token) => {
      handlers.contentApiToken.onFocus();
      handlers.contentApiToken.onChange(token);
      handlers.contentApiToken.onBlur({ target: { value: token } });
      setShowFileUpload(false);
    },
    [handlers, setShowFileUpload],
  );

  useEffect(() => {
    if (hasApiError) {
      removeToken();
    }
  }, [hasApiError]);

  return (
    <>
      <div>
        <Field title={t('Google Merchant ID')}>
          <Input
            block
            disabled={!canEdit || submitting}
            value={googleMerchantId}
            status={errors.googleMerchantId !== '' ? 'invalid' : 'valid'}
            renderMessages={renderMessages.googleMerchantId}
            e2eInputId={`${E2E_PREFIX}-google-merchant-id-input`}
            e2eMessagesId={`${E2E_PREFIX}-google-merchant-id-errors`}
            {...handlers.googleMerchantId}
          />
        </Field>
      </div>
      <div>
        <Field title={t('Preorder Period')}>
          <Input
            block
            disabled={!canEdit || submitting}
            value={preorderPeriod}
            status={errors.preorderPeriod !== '' ? 'invalid' : 'valid'}
            renderMessages={renderMessages.preorderPeriod}
            e2eInputId={`${E2E_PREFIX}-preorder-period-input`}
            e2eMessagesId={`${E2E_PREFIX}-preorder-period-errors`}
            preTextPosition="RIGHT"
            renderPreText={renderPreorderPeriodPreText}
            {...handlers.preorderPeriod}
          />
        </Field>
      </div>
      <Divider spacing="32px" />
      <div>
        <Text fontType="Body" weight="Semibold">
          {t('Authorize Content API Title')}
        </Text>
        <Spacer y="4px" />
        <Text fontType="Body" color="INK_600">
          <Trans i18nKey="Authorize Content API Description" ns="main">
            link 1:
            <a href={FAQHref} target="_blank" rel="noreferrer">
              FAQ
            </a>
          </Trans>
        </Text>
        <Spacer y="16px" />
        {showFileUpload && (
          <StyledImageUploadZone
            isError={hasApiError}
            onChange={(_, files) => processToken(files[0]).then(setToken)}
            onError={(e) => {
              console.error(e);
              setToken(null);
            }}
            lang={i18n.language}
            messages={{
              title: t('Upload Files Here'),
              error: t('Invalid Credential File'),
            }}
            accept="application/JSON"
            renderIcon={() => <Icons.Outline.Import size="XLARGE" />}
          />
        )}
        {!showFileUpload && (
          <SelectedFileBox>
            <div
              className={`fileName ${hasSelectedToken ? 'hoverable' : ''}`}
              onClick={() =>
                hasSelectedToken &&
                downloadText(contentApiToken.name, contentApiToken.content)
              }
            >
              <Icons.Solid.Note size="XLARGE" />
              {contentApiToken?.name || adminEmail}
            </div>
            <div className="trashCan" onClick={removeToken}>
              <Icons.Solid.Trash color="RED_600" size="MEDIUM" />
            </div>
          </SelectedFileBox>
        )}
      </div>
      <Spacer y="8px" />
      <Text fontType="Body" color="RED_600">
        {t('Save before leaving')}
      </Text>
      <FormFooter>
        <Button.Primary
          width="WIDE"
          e2eId={`${E2E_PREFIX}-save-btn`}
          onClick={handleSubmit}
          disabled={!canEdit || !touched || hasErrors || submitting}
        >
          {t('Save', { ns: 'common' })}
        </Button.Primary>
      </FormFooter>
    </>
  );
};

export default MerchantForm;
