const main = {
  'App support link':
    'https://support.shoplineapp.com/hc/en-us/articles/23966890906649',
  'Config GMC':
    'Google Merchant Center - Shopping Ads and LIVE Shopping Settings',
  'Config GMC Caption':
    'Through the Content API connection, you can automatically upload your product information to Google Merchant Center, which can be used in Google ads, YouTube LIVE Shopping feature and other Google services.',
  'Google Merchant ID': 'Google Merchant Center Account ID',
  'Preorder Period': 'Availability Date',
  'Merchant has been saved successfully':
    'Settings have been saved successfully',
  'Failed to save merchant': 'Failed to save merchant',
  'Google Merchant ID Invalid':
    'Please be sure to copy gserviceaccount link and add the following link to Google Merchant Center. In order to successfully save/use the Content API to instantly upload products to GMC.',
  'Google Merchant ID Invalid description':
    'Please note: There may be a delay in data review after setting up GMC, please wait patiently; if you still cannot set up successfully after 24 hours, please contact us!',
  'Successfully set the Region and Language for Ads Delivery':
    'Successfully set the Region and Language for Ads Delivery',
  'Please enter numbers only': 'Please enter numbers only',
  'Upload products to GMC': 'Upload products to GMC',
  'Upload products to GMC - description':
    'Please be sure to copy and add the following link to Google Merchant Center to successfully use the Content API to instantly upload products to GMC.',
  'Upload products to GMC - step 1':
    '1. Click the icon on the right to copy Email',
  'Upload products to GMC - step 2':
    '2. Go to Google Merchant Center > Tools and Settings (click the middle gear icon in the upper right corner) > Account Access > Users',
  'Upload products to GMC - step 3':
    '3. Add a new user and share administrator rights to the user',
  'Save before leaving':
    '*Note, please save the content before leaving this page',
  'Authorize Content API Title': 'Authorize Content API service account access',
  'Authorize Content API Description':
    'Please follow the <1>FAQ</1> instructions, go to Google Merchant Center to create Service Account Credentials & download the json key file.',
  'Upload Files Here': 'Upload Files Here',
  'Invalid Credential File':
    'Credential file is not valid, please check or create a new credential key.',

  'Config region & locale': 'Placement Area and Placement Language Settings',
  'Config region & locale Caption':
    'Once connected to Google Merchant Center, your product feed can be managed and automatically updated at all times. Google Merchant Center will display your product ads based on the delivery area and language you set, and upload your general products, pre-order products, and hidden products to YouTube LIVE Shopping through Google Merchant Center to facilitate smooth launch.',
  Region: 'Placement Area',
  Locale: 'Placement Language',
  'Add new languages to the list below': 'Add new languages to the list below',
  'EmptyLocale - title': 'There’s no data yet',
  'EmptyLocale - subtitle': 'Add a language from above settings',
  DATA_TABLE: {
    REGION: 'Placement Area',
    LOCALE: 'Placement Language',
    ACTION: 'Turn on/off',
  },
  LOCALE: {
    'zh-hant': 'Traditional Chinese',
    'zh-tw': 'Traditional Chinese',
    'zh-hk': 'Traditional Chinese',
    'zh-cn': 'Simplified Chinese',
    en: 'English',
    ms: 'Malay',
    th: 'Thai',
    vi: 'Vietnamese',
    id: 'Indonesian',
    de: 'German',
    fr: 'French',
    ja: 'Japanese',
  },
  REGION: {
    TW: 'Taiwan',
    CN: 'China',
    HK: 'Hong Kong',
    SG: 'Singapore',
    MY: 'Malaysia',
    TH: 'Thailand',
    VN: 'Vietnam',
    PH: 'Philippines',
    US: 'United States',
    AU: 'Australia',
    GB: 'United Kingdom',
  },
  UNIT: {
    days: 'days',
  },
};

export default main;
