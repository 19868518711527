const main = {
  'App support link':
    'https://support.shoplineapp.com/hc/zh-tw/articles/23966890906649',
  'Config GMC': 'Google Merchant Center - 購物廣告及直播購物設定',
  'Config GMC Caption':
    '透過 Content API 串接能自動上載你的商品資料到 Google Merchant Center，可以在 Google 廣告、YouTube LIVE Shopping 直播購物功能及其他 Google 服務中使用。',
  'Google Merchant ID': 'Google Merchant Center 帳號',
  'Preorder Period': '出貨遞延天數',
  'Merchant has been saved successfully': '商家設定已成功保存',
  'Failed to save merchant': '商家設定保存失敗',
  'Google Merchant ID Invalid':
    '請務必將 gserviceaccount 連結複製並加入 Google Merchant Center，才能成功儲存並使用 Content API 即時上傳商品至 GMC 功能。',
  'Google Merchant ID Invalid description':
    '請注意：於 GMC 設定後可能有資料審核延遲問題，請耐心等候；如 24 小時後仍無法成功設置，請聯繫我們！',
  'Successfully set the Region and Language for Ads Delivery':
    '成功設定廣告投放地區及語系',
  'Please enter numbers only': '僅限輸入數字',
  'Upload products to GMC': '上傳商品至 GMC 功能',
  'Upload products to GMC - description':
    '請務必將以下連結複製並加入 Google Merchant Center，方能成功使用 Content API 即時上傳商品至 GMC 功能',
  'Upload products to GMC - step 1': '1. 點擊右邊 icon 複製 Email',
  'Upload products to GMC - step 2':
    '2. 進入 Google Merchant Center > 工具與設定（右上角偏中間齒輪 icon 點入） > 帳戶存取權 > 使用者',
  'Upload products to GMC - step 3': '3. 新增使用者並 Share 管理員權限至使用者',
  'Save before leaving': '*注意，請在離開此頁之前，先儲存內容',
  'Authorize Content API Title': '授權 Content API 服務帳戶使用權限',
  'Authorize Content API Description':
    '請依照 <1>FAQ</1> 指示，前往 Google Merchant Center 後台下載 Content API 服務帳號憑證，並將憑證檔案上傳於此',
  'Upload Files Here': '上傳檔案',
  'Invalid Credential File': '檔案無效，請檢查或更新你的憑證檔案',

  'Config region & locale': '投放地區及語系設定',
  'Config region & locale Caption':
    '連接 Google Merchant Center 後，可以在此管理及無時無刻自動更新產品動態饋給。Google Merchant Center 會根據你設定的投放地區及語系將你的商品廣告展示出去、並將你的一般商品、預購商品、隱藏商品透過 Google Merchant Center 上傳至 YouTube LIVE Shopping，以利順利開播',
  Region: '投放地區',
  Locale: '投放語系',
  'Add new languages to the list below': '新增語系到下面列表',
  'EmptyLocale - title': '還沒有數據',
  'EmptyLocale - subtitle': '從上面的設置中添加語言',
  DATA_TABLE: {
    REGION: '地區',
    LOCALE: '語系',
    ACTION: '開啟/關閉',
  },
  LOCALE: {
    'zh-hant': '繁體中文',
    'zh-tw': '繁體中文',
    'zh-hk': '繁體中文',
    'zh-cn': '簡體中文',
    en: '英文',
    ms: '馬來文',
    th: '泰文',
    vi: '越南文',
    id: '印尼文',
    de: '德文',
    fr: '法文',
    ja: '日文',
  },
  REGION: {
    TW: '台灣',
    CN: '中國',
    HK: '香港',
    SG: '新加坡',
    MY: '馬來西亞',
    TH: '泰國',
    VN: '越南',
    PH: '菲律賓',
    US: '美國',
    AU: '澳洲',
    GB: '英國',
  },
  UNIT: {
    days: '天',
  },
};

export default main;
