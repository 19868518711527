import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  cond,
  flow,
  curry,
  constant,
  identity,
  matches,
  negate,
  stubTrue,
} from 'lodash-es';
import pick from 'lodash/fp/pick';
import defaults from 'lodash/fp/defaults';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import usePermissions from 'hooks/usePermissions';

export const pickMerchant = flow(
  pick('googleMerchantId'),
  defaults({ googleMerchantId: '' }),
);

const getRegionAndLocaleSets = async (merchantId) => {
  const { data } = await axios.get(
    `/api/merchants/${merchantId}/region-locale-sets`,
  );
  return data.data;
};

const createRegionAndLocaleSet = async (merchantId, regionLocaleSet) => {
  const { data } = await axios.post(
    `/api/merchants/${merchantId}/region-locale-sets`,
    regionLocaleSet,
  );
  return data;
};

const removeRegionAndLocaleSet = async (merchantId, regionLocaleSetId) => {
  const { data } = await axios.delete(
    `/api/merchants/${merchantId}/region-locale-sets/${regionLocaleSetId}`,
  );
  return data;
};

const updateRegionAndLocaleSet = async (
  merchantId,
  { setId: regionLocaleSetId, value },
) => {
  const { data } = await axios.patch(
    `/api/merchants/${merchantId}/region-locale-sets/${regionLocaleSetId}`,
    value,
  );
  return data;
};

const curriedCreateRegionLocaleSet = curry(createRegionAndLocaleSet, 2);
const curriedRemoveRegionLocaleSet = curry(removeRegionAndLocaleSet, 2);
const curriedUpdateRegionLocaleSet = curry(updateRegionAndLocaleSet, 2);

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [, merchantId] }) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId) => [
  'merchant-region-locale-sets',
  merchantId,
];

export const useQueryRegionAndLocalesSets = () => {
  const { merchantId } = useParams();
  const canIndex = usePermissions('regionLocaleSet', 'index');
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getRegionAndLocaleSets),
    {
      enabled: canIndex && !!merchantId,
    },
  );
};

export const useCreateRegionAndLocalesSet = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedCreateRegionLocaleSet(merchantId), {
    onSuccess: (...args) => {
      queryClient.setQueryData(
        composeQueryKey(merchantId),
        (regionLocaleSets) => [...regionLocaleSets, args[0].data],
      );
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};

export const useRemoveRegionAndLocalesSet = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedRemoveRegionLocaleSet(merchantId), {
    onSuccess: (...args) => {
      queryClient.setQueryData(
        composeQueryKey(merchantId),
        filter(negate(matches({ id: args[0].data.id }))),
      );
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};

export const useUpdateRegionAndLocalesSet = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedUpdateRegionLocaleSet(merchantId), {
    onSuccess: (...args) => {
      queryClient.setQueryData(
        composeQueryKey(merchantId),
        map(
          cond([
            [matches({ id: args[0].data.id }), constant(args[0].data)],
            [stubTrue, identity],
          ]),
        ),
        filter(negate(matches({ id: args[0].data.id }))),
      );
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};
