import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { flow, curry } from 'lodash-es';
import pick from 'lodash/fp/pick';
import defaults from 'lodash/fp/defaults';
import usePermissions from 'hooks/usePermissions';

export const pickMerchant = flow(
  pick(['googleMerchantId', 'preorderPeriod', 'contentApiToken']),
  defaults({
    googleMerchantId: '',
    preorderPeriod: 364,
    contentApiToken: null,
  }),
);

const getMerchant = async (merchantId) => {
  const { data } = await axios.get(`/api/merchants/${merchantId}`);
  return pickMerchant(data);
};

const patchMerchant = async (merchantId, merchant) => {
  const { data } = await axios.patch(`/api/merchants/${merchantId}`, merchant);
  return pickMerchant(data);
};

const curriedPatchMerchant = curry(patchMerchant, 2);

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [_, merchantId] }) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId) => ['merchant', merchantId];

export const useQueryMerchant = () => {
  const { merchantId } = useParams();
  const canShow = usePermissions('merchant', 'show');
  return useQuery(composeQueryKey(merchantId), passMerchantId(getMerchant), {
    enabled: canShow && !!merchantId,
  });
};

export const useMutateMerchant = ({ onSuccess, onError }) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedPatchMerchant(merchantId), {
    onSuccess: (...args) => {
      queryClient.setQueryData(composeQueryKey(merchantId), args[1]);
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
    onError,
  });
};
