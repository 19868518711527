const main = {
  'App support link':
    'https://support.shoplineapp.com/hc/zh-cn/articles/23966890906649',
  'Config GMC': 'Google Merchant Center - 购物广告及直播购物设定',
  'Config GMC Caption':
    '透过 Content API 串接能自动上载你的商品资料到 Google Merchant Center，可以在 Google 广告、YouTube LIVE Shopping 直播购物功能及其他 Google 服务中使用。',
  'Google Merchant ID': 'Google Merchant Center 帐号',
  'Preorder Period': '出货递延天数',
  'Merchant has been saved successfully': '商家设定已成功保存',
  'Failed to save merchant': '商家设置保存失败',
  'Google Merchant ID Invalid':
    '请务必将 gserviceaccount 连结复制并加入 Google Merchant Center，才能成功储存并使用 Content API 即时上传商品至 GMC 功能。',
  'Google Merchant ID Invalid description':
    '请注意：于 GMC 设定后可能有资料审核延迟问题，请耐心等候；如 24 小时后仍无法成功设置，请联系我们！',
  'Successfully set the Region and Language for Ads Delivery':
    '成功设定广告投放地区及语系',
  'Please enter numbers only': '仅限输入数字',
  'Upload products to GMC': '上传商品至 GMC 功能',
  'Upload products to GMC - description':
    '请务必将以下连结复制并加入 Google Merchant Center，方能成功使用 Content API 即时上传商品至 GMC 功能',
  'Upload products to GMC - step 1': '1. 点击右边 icon 复制 Email',
  'Upload products to GMC - step 2':
    '2. 进入 Google Merchant Center > 工具与设定（右上角偏中间齿轮 icon 点入） > 帐户存取权 > 使用者',
  'Upload products to GMC - step 3': '3. 新增使用者并 Share 管理员权限至使用者',
  'Save before leaving': '*注意，请在离开此页之前，先储存内容',
  'Authorize Content API Title': '授权 Content API 服务帐户使用权限',
  'Authorize Content API Description':
    '请依照 <1>FAQ</1> 指示，前往 Google Merchant Center 后台下载 Content API 服务帐号凭证，并将凭证档案上传于此',
  'Upload Files Here': '上传档案',
  'Invalid Credential File': '档案无效，请检查或更新你的凭证档案',

  'Config region & locale': '投放地区及语系设定',
  'Config region & locale Caption':
    '连接 Google Merchant Center 后，可以在此管理及无时无刻自动更新产品动态馈给。 Google Merchant Center 会根据你设定的投放地区及语系将你的商品广告展示出去、并将你的一般商品、预购商品、隐藏商品透过 Google Merchant Center 上传至 YouTube LIVE Shopping，以利顺利开播',
  Region: '投放地区',
  Locale: '投放语系',
  'Add new languages to the list below': '新增语系到下面列表',
  'EmptyLocale - title': '还没有数据',
  'EmptyLocale - subtitle': '从上面的设置中添加语言',
  DATA_TABLE: {
    REGION: '地区',
    LOCALE: '语系',
    ACTION: '开启/关闭',
  },
  LOCALE: {
    'zh-hant': '繁体中文',
    'zh-tw': '繁体中文',
    'zh-hk': '繁体中文',
    'zh-cn': '简体中文',
    en: '英语',
    ms: '马来语',
    th: '泰语',
    vi: '越南语',
    id: '印尼语',
    de: '德语',
    fr: '法语',
    ja: '日语',
  },
  REGION: {
    TW: '台湾',
    CN: '中国',
    HK: '香港',
    SG: '新加坡',
    MY: '马来西亚',
    TH: '泰国',
    VN: '越南',
    PH: '菲律宾',
    US: '美国',
    AU: '澳大利亚',
    GB: '英国',
  },
  UNIT: {
    days: '天',
  },
};

export default main;
